<template>
  <div style="width: 80%;margin-left: auto !important;margin-right: auto !important">
    <div class="hello">
      <h1>FADAS</h1>
      <h2>Die Software zur Datenverwaltung die Ihr Leben leichter macht.</h2>
      <p>Einzelne Notizzettel, unübersichtliche Terminplanung und umständliche Rechnungserstellung. Diese Probleme gehören mit der FADAS Software der Vergangenheit an.
        Im FADAS können Sie all Ihre Kundendaten speichern, Termine übersichtlich planen und anschließend Rechnungen mit nur einem Klick erstellen.
        Alles was Sie hierfür benötigen, ist ein internetfähiges Gerät. Dies kann ein Handy, Tablet, Laptop oder auch alles davon sein.
        Die Daten Ihrer Kunden liegen SICHER und jederzeit für Sie zugänglich auf einem Server in Deutschland.
      </p>
      <p style="padding-top: 0.5rem">Bei Interesse melden Sie sich über die <a href="#kontakt" style="text-decoration: underline">Kontaktdaten</a>.</p>
    </div>
    <hr>
    <div class="hello" id="uberblick">
      <h2>Die wichtigsten Funktionen im Überblick:</h2>
      <ul>
        <li>Speicherung von Kundendaten</li>
        <li>Terminplanung</li>
        <li>Rechnungserstellung</li>
        <li>Terminverwaltung für Mitarbeiter</li>
        <li>Rechnungsmanagement</li>
        <li>Einnahmen und Ausgaben überblicken</li>
        <li>Materiallager</li>
        <li>Benachrichtigungen</li>
        <li>umfangreiche Filterfunktionen für Kundendaten</li>
      </ul>
      <p style="padding-top: 0.5rem">Bei Interesse melden Sie sich über die <a href="#kontakt" style="text-decoration: underline">Kontaktdaten</a>.</p>
    </div>
    <hr>
    <div class="hello" id="gruende">
      <h2>Warum Sie das FADAS Nutzen sollten:</h2>
      <ul style="list-style-type:none;">
        <li class="spacing">
          <h3>Einheitliche Speicherung von Kundendaten</h3>
          <p>Alle Daten Ihrer Kunden werden einheitlich, übersichtlich und jederzeit erreichbar gespeichert. Dies beinhaltet Name, Adresse, Kontaktdaten, Pferde und Ställe.
            Dank der umfangreichen Filterfunktionen können Sie gezielt Daten auslesen und bearbeiten.</p>
        </li>
        <li class="spacing">
          <h3>Übersichtliche Terminplanung</h3>
          <p>Die Termine Ihrer Kunden werden übersichtlich in einem Kalender dargestellt.
            Somit wissen Sie jederzeit wann der letzte Termin eines Kunden war und wann vorraussichtlich der nächste Termin ansteht.</p>
        </li>
        <li class="spacing">
          <h3>Einfache Rechnungserstellung</h3>
          <p>Im FADAS können Rechnungen mit nur einem Klick erstellt werden. Diese können individuell an Ihre Bedürfnisse angepasst werden.
            Wenn Sie beispielsweise Kleinunternehmer sind, müssen Sie nach §19 Abs. 1 UStG keine Umsatzsteuer berechnen.
            Im FADAS benötigt dies nur eine einmalige Einstellung und alle Rechnungen werden korrekt erstellt.
            Falls Sie einen Steuerberater haben, können Sie diesem all Ihre Rechnungen in einer Datei digital zukommen lassen. </p>
        </li>
        <li class="spacing">
          <h3>Überblick über Einnahmen und Ausgaben</h3>
          <p>Sie können all Ihre betrieblichen Ausgaben im FADAS speichern und erhalten im Anschluss detaillierte Auswertungen über den zeitlichen Verlauf
          Ihrer Einnahmen und Ausgaben. Damit erhalten Sie einen umfangreichen Überblick über Ihren Gewinn und Verlust. Mithilfe der Übersicht können Sie Trends
          erkennen und Ihre Firmenstrategie planen.</p>
        </li>
        <li class="spacing">
          <h3>Übersichtliches Materiallager</h3>
          <p>Sie können im FADAS ihre Arbeitsmaterialien anlegen und verwalten. Damit überblicken Sie jederzeit, ob und wann Sie neues Material bestellen müssen.
          Verwendetes Material können Sie direkt für jeden Termin aus dem Lager ausbuchen.</p>
        </li>
        <li class="spacing">
          <h3>Zugriff von Überall</h3>
          <p>Sie können das FADAS über jedes internetfähige Gerät erreichen. Damit können Sie problemlos ortsunabhängig Daten eingeben und später von Zuhause wieder abrufen.
            Dabei gehen keine Daten verloren und Sie behalten volle Flexibilität.</p>
        </li>
        <li class="spacing">
          <h3>Benachrichtigungen für wichtige Ereignisse</h3>
          <p>Das FADAS informiert Sie über wichtige Ereignisse wie beispielsweise ein Material, welches nachgefüllt werden muss. Zudem erhalten Sie eine Information, wenn
          ein Kunde seine Rechnung über längere Zeit nicht bezahlt hat. Diese Benachrichtigungen lassen sich individuell aktivieren und deaktivieren. Sie erhalten die
            Benachrichtigungen sowohl im FADAS als auch, wenn gewünscht per Mail, sodass keine Nachricht verloren geht. </p>
        </li>
        <li class="spacing">
          <h3>Mitarbeiterverwaltung</h3>
          <p>Das FADAS bietet die Möglichkeit mehrere Mitarbeiter anzulegen. Diesen können Sie Termine zuweisen oder sie eigenständig Termine erstellen lassen, um auch bei
            mehreren Mitarbeitern den Überblick zu behalten.</p>
        </li>
        <li class="spacing">
          <h3>Benutzerfreundliche Eingabe</h3>
          <p>Das FADAS ermöglicht durch die benutzerfreundliche Oberfläche ein intuitives Arbeiten ohne lange Einarbeitungszeit. Das Programm schlägt bereits gespeicherte
            Eingaben vor, wodurch der Arbeitsfluss vereinfacht wird. Dabei werden Sie auf bereits eingegebene oder fehlende Daten hingewiesen.</p>
        </li>
        <li class="spacing">
          <h3>Datensicherheit</h3>
          <p>Die Daten von Ihnen und Ihren Kunden sind jederzeit vor unbefugtem Zugriff geschützt.
            Dank moderner Sicherheitstechologien sind die Daten im gesammten Verarbeitungs- und Übertragungsprozess verschlüsselt.
            Damit ist das FADAS mit der DSGVO konform.</p>
        </li>
        <li class="spacing">
          <h3>Updates</h3>
          <p>Durch Updates wird das FADAS vortlaufend mit den neusten Technologien versorgt, wobei auch der Funktionsumfang stetig wächst.
            Das Beste daran ist: Sie müssen überhaupt nichts tun.
            Die Updates werden automatisch übertragen und Sie nutzen jederzeit die neuste Version über all Ihr Geräte.</p>
        </li>
      </ul>
      <p style="padding-top: 0.5rem">Bei Interesse melden Sie sich über die <a href="#kontakt" style="text-decoration: underline">Kontaktdaten</a>.</p>
    </div>
    <hr>
    <div class="hello" style="align-content: center" id="kontakt">
      <h2>Kontakt</h2>
      <p style="padding-bottom: 2rem">Instagram: @farrierdatasystem <br>E-Mail: max.freiberg@freenet.de <br>
        Tel.: 01781432411</p>
      <h2>Preis</h2>
      <p>50€ pro Monat für den ersten Account<br>
      25€ pro Monat für jeden weiteren Account </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hello{
  width: 830px;
  margin-right: auto !important;
  margin-left: auto !important;
  align-content: start;
  display: block;
}

@media only screen and (max-width: 1050px) {
  .hello{
    width: 500px;
  }
}

@media only screen and (max-width: 680px) {
  .hello{
    width: 250px;
  }

}
.spacing{
  margin-bottom: 1rem !important;
}
h1,h2,p,ul{
  text-align: start;
}
</style>
